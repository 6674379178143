var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Modal',{ref:"UserChangePasswordModal",attrs:{"id":"UserChangePasswordModal","title":"Change Password","size":"md"}},[_c('form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[_c('div',{},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"label",attrs:{"for":"current-password"}},[_vm._v("Current Password:")])]),_c('InputTypeVue',{attrs:{"type":"password","id":"current-password","value":_vm.passwordList.current,"placeholder":"Current Password"},on:{"input":(e) => (_vm.passwordList.current = e)}}),((_vm.isSubmit) && _vm.passwordList.current == '')?_c('span',{staticClass:"text-danger invalid-feedback d-block mt-1 ml-2"},[_vm._v("Please enter current password.")]):_vm._e(),(_vm.invalidPasswordError != '')?_c('span',{staticClass:"text-danger invalid-feedback d-block mt-1 ml-2"},[_vm._v("The password you entered is incorrect")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12 mt-3"}},[_c('label',{staticClass:"label",attrs:{"for":"new-password"}},[_vm._v("New Password:")]),_c('InputTypeVue',{attrs:{"type":"password","id":"new-password","value":_vm.passwordList.new,"placeholder":"New Password"},on:{"input":(e) => (_vm.passwordList.new = e)}}),((!_vm.isSubmit)&&_vm.passwordList.new == '')?_c('div',{staticClass:"passwordInfo"},[_vm._v(" Your password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.")]):((_vm.isSubmit) && _vm.passwordList.new == '')?_c('span',{staticClass:"text-danger invalid-feedback d-block mt-1 ml-2"},[_vm._v("New password can't be empty.")]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-between password-strength-bar mt-2",staticStyle:{"height":"20px"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('div',{staticClass:"bar mx-2",class:[
                        _vm.calcPassStrength == 0
                          ? 'bg-light'
                          : _vm.calcPassStrength >= 20 && _vm.calcPassStrength <= 60
                          ? 'bg-danger'
                          : _vm.calcPassStrength >= 60 && _vm.calcPassStrength <= 80
                          ? 'bg-warning'
                          : 'bg-success',
                      ],staticStyle:{"width":"20%","height":"10px","background":"red"}}),_c('div',{staticClass:"bar mx-2",class:[
                        _vm.calcPassStrength < 60
                          ? 'bg-light'
                          : _vm.calcPassStrength == 60
                          ? 'bg-danger'
                          : _vm.calcPassStrength > 60 && _vm.calcPassStrength <= 80
                          ? 'bg-warning'
                          : 'bg-success',
                      ],staticStyle:{"width":"20%","height":"10px","background":"red"}}),_c('div',{staticClass:"bar mx-2",class:[
                        _vm.calcPassStrength < 80
                          ? 'bg-light'
                          : _vm.calcPassStrength == 80
                          ? 'bg-warning'
                          : 'bg-success',
                      ],staticStyle:{"width":"20%","height":"10px","background":"red"}}),_c('div',{staticClass:"bar mx-2",class:[
                        _vm.calcPassStrength < 100 ? 'bg-light' : 'bg-success',
                      ],staticStyle:{"width":"20%","height":"10px","background":"red"}})]),_c('div',{staticClass:"bar mx-2 float-right font-weight-bold",class:_vm.calcPassStrength <= 60
                        ? 'text-danger'
                        : _vm.calcPassStrength <= 80
                        ? 'text-warning'
                        : 'text-success',staticStyle:{"font-size":"14px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.calcPassStrength == 0
                          ? ''
                          : _vm.calcPassStrength <= 60
                          ? 'Weak'
                          : _vm.calcPassStrength <= 80
                          ? 'Medium'
                          : 'Strong'
                      )}})])])],1),_c('b-col',{attrs:{"cols":"12 mt-3"}},[_c('label',{staticClass:"label",attrs:{"for":"repeat-password"}},[_vm._v("Confirm new password:")]),_c('InputTypeVue',{attrs:{"type":"password","id":"repeat-password","value":_vm.passwordList.repeat,"placeholder":"Confirm new password"},on:{"input":(e) => (_vm.passwordList.repeat = e)}}),(_vm.isSubmit && (_vm.passwordList.new != _vm.passwordList.repeat))?_c('span',{staticClass:"text-danger invalid-feedback d-block mt-1 ml-2"},[_vm._v("New and Confirm password must be same.")]):_vm._e()],1),_c('b-col',{staticClass:"d-flex justify-content-center mt-3",attrs:{"cols":"12"}},[_c('Button',{attrs:{"disabled":!_vm.validateFormSubmission,"isTick":true,"isLoading":_vm.submitWithoutError}},[_vm._v(" Save Changes ")])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }